import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Remote Management Required</AlertTitle>
  <div>
    To use webhooks, your CA must be configured for{` `}
    <Link href="/docs/step-ca/provisioners/#remote-provisioner-management" mdxType="Link">
    remote management</Link>.
  </div>
    </Alert>
    <p>{`Webhooks let you enrich X.509 or SSH certificates with data requested from an external endpoint.
The CA fetches key/value pairs from a configured webhook when a CSR is being processed,
and the webhook data is passed along to the provisioner's `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates"
      }}>{`certificate template`}</a>{`.`}</p>
    <p>{`Attach webhooks to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
      }}>{`provisioners`}</a>{` via the `}<inlineCode parentName="p">{`step ca provisioner webhook`}</inlineCode>{` command group.`}</p>
    <h2>{`Quickstart`}</h2>
    <p>{`We've created an `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/webhooks"
      }}>{`example webhook server`}</a>{`.
You can use it to try out webhooks locally,
or as the basis of your own custom webhook integration.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Initialize a new Certificate Authority with remote management enabled.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca init --remote-management --context webhooks
`}</code></pre>
        <p parentName="li">{`Then, in another window, start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step-ca --context webhooks
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Clone the `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/webhooks"
          }}>{`example webhook repository`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`git clone https://github.com/smallstep/webhooks.git
cd webhooks
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate a server certificate for your webhook server.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca certificate localhost webhook.crt webhook.key
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Export your root CA certificate for the webhook server.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca root > root_ca.crt
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a new provisioner to associate with your webhook.
When asked, the default admin username is `}<inlineCode parentName="p">{`step`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca provisioner add my_provisioner --create
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a webhook to your provisioner for a webhook server that will be listening on `}<inlineCode parentName="p">{`localhost:9443`}</inlineCode>{`.`}</p>
        <p parentName="li">{`☠️ Don't add your webhook to the default (administrative) CA provisioner!
You may lock yourself out of your CA.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca provisioner webhook add my_provisioner people --url 'https://localhost:9443/{{ .Token.sub }}'
`}</code></pre>
        <p parentName="li">{`This command will print out the ID and secret for the `}<inlineCode parentName="p">{`people`}</inlineCode>{` webhook.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update the `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/webhooks/blob/f6a74c2e30dcb19b15d9903fdb6271a8358d26cd/main.go#L31"
          }}><inlineCode parentName="a">{`webhookIDsToSecrets`}</inlineCode>{` map`}</a>{` in the webhook server's `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file with the ID and secret printed above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update the `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/webhooks/blob/f6a74c2e30dcb19b15d9903fdb6271a8358d26cd/main.go#L26"
          }}><inlineCode parentName="a">{`db`}</inlineCode>{` map`}</a>{` in the webhook server's `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file with the Common Name (CN) you will be issuing test certificates for.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start the webhook server:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`go run main.go
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a template to the provisioner to incorporate the data returned from the `}<inlineCode parentName="p">{`people`}</inlineCode>{` webhook:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`cat <<EOF > external_ou.tmpl
{
  "subject": {
    "organizationalUnit": {{ toJson .Webhooks.people.role }}
  }
}
EOF
step ca provisioner update my_provisioner --x509-template external_ou.tmpl
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Get a certificate with the provisioner you configured earlier:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step ca certificate andrew@smallstep.com my.crt my.key --provisioner my_provisioner
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Inspect the certificate. Notice that the user's role returned from the webhook server appears in the OU.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step certificate inspect my.crt --format json | jq .subject
`}</code></pre>
      </li>
    </ol>
    <h2>{`Implementation Details`}</h2>
    <h3>{`Webhook Server Response`}</h3>
    <p>{`The webhook server must include `}<inlineCode parentName="p">{`"allow": true`}</inlineCode>{` in the response body,
or `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will refuse to sign the certificate request.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` template engine augments the template data with the `}<inlineCode parentName="p">{`data`}</inlineCode>{` object in the response body.`}</p>
    <p>{`For example, a webhook server could send the following JSON response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "allow": true,
  "data": {
    "role": "eng"
  }
}
`}</code></pre>
    <p>{`A template on the provisioner will then be able to reference the response under the path `}<inlineCode parentName="p">{`.Webhooks.webhook_name`}</inlineCode>{`.
If the webhook was named `}<inlineCode parentName="p">{`people`}</inlineCode>{`, the role in the webhook response could be accessed in a template under the field `}<inlineCode parentName="p">{`.Webhooks.people.role`}</inlineCode>{`.`}</p>
    <h3>{`Requests`}</h3>
    <p>{`All requests will use the `}<inlineCode parentName="p">{`POST`}</inlineCode>{` method to send a JSON body to the webhook server containing a `}<inlineCode parentName="p">{`timestamp`}</inlineCode>{` field.
Additional data will vary based on the type of the certificate being signed.
The `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/webhooks"
      }}>{`webhooks server example repository`}</a>{` contains examples in Go of parsing webhook request bodies for both X.509 and SSH certificate requests.`}</p>
    <h4>{`X.509 Request Body`}</h4>
    <p>{`For X.509 certificates, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will include an `}<inlineCode parentName="p">{`x509CertificateRequest`}</inlineCode>{` field
that will hold a `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/go.step.sm/crypto@v0.23.1/x509util#CertificateRequest"
      }}>{`JSON representation of the request`}</a>{`
using the same schema as `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/templates/#x509-templates"
      }}>{`x509 template functions`}</a>{`
with a `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/blob/c169defc73db6ba4b83e1acd5bd31feafb4df050/webhook/types.go#L19-L22"
      }}>{`few extra fields`}</a>{`.`}</p>
    <p>{`You can use webhooks to enrich device certificates issued via an ACME `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` challenge.
When used with the `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/provisioners#acme"
      }}>{`ACME provisioner`}</a>{`
and the ACME `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` challenge,
the request body will also contain the verified device ID in the `}<inlineCode parentName="p">{`attestationData.permanentIdentifier`}</inlineCode>{` field.`}</p>
    <h4>{`SSH Request Body`}</h4>
    <p>{`For SSH certificates `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will include an `}<inlineCode parentName="p">{`sshCertificateRequest`}</inlineCode>{` field with `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/blob/c169defc73db6ba4b83e1acd5bd31feafb4df050/webhook/types.go#L37"
      }}>{`data from the request`}</a>{`.`}</p>
    <h3>{`Authentication`}</h3>
    <p>{`Your webhook server must authenticate each request from the CA.
To achieve this, the CA sends a signature of its payload in the webhook request header,
and the webhook server must confirm this signature.
The signature also tells the webhook server which webhook is currently being executed.`}</p>
    <p>{`In addition the the signature header, you can optionally enable two other authentication schemes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Authorization Header`}</strong><br parentName="li"></br>
        {`The CA can send a bearer token or username and password in an `}<inlineCode parentName="li">{`Authentication`}</inlineCode>{` header.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mutual TLS`}</strong><br parentName="li"></br>
        {`The webhook server can require and verify the connection using mutual TLS. The CA will provide a client certificate when requested.`}</li>
    </ul>
    <h4>{`Signature`}</h4>
    <p>{`Every webhook has a unique secret that will be displayed when the webhook is created
via `}<inlineCode parentName="p">{`step ca provisioner webhook add`}</inlineCode>{`.
`}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will include a signature of the payload in the `}<inlineCode parentName="p">{`X-Smallstep-Signature`}</inlineCode>{` header.
The webhook ID will also be included in the `}<inlineCode parentName="p">{`X-Smallstep-Webhook-ID`}</inlineCode>{` header
to help associate the correct signing secret with the webhook request.
Webhook servers must compute an HMAC with the SHA256 hash function,
using the webhook signing secret as the key and the request body as the message.
See the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/webhooks"
      }}>{`webhook server example repository`}</a>{` for an example of verifying the signature.`}</p>
    <h4>{`Authorization Header (optional)`}</h4>
    <p>{`For an additional layer of security, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` may be configured to send
either a bearer token
or a `}<inlineCode parentName="p">{`username:password`}</inlineCode>{` in the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header.`}</p>
    <p>{`To use a bearer token, run:`}</p>
    <pre><code parentName="pre" {...{}}>{`step ca provisioner webhook update my_provisioner my_webhook --bearer-token abc123xyz
`}</code></pre>
    <p>{`Or, to use basic authentication, run:`}</p>
    <pre><code parentName="pre" {...{}}>{`step ca provisioner webhook update my_provisioner my_webhook --basic-auth-username user --basic-auth-password-file pass.txt
`}</code></pre>
    <h4>{`Mutual TLS (optional)`}</h4>
    <p>{`You can also use mutual TLS to authenticate `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` as a client of your webhook server.`}</p>
    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will send the CA's self-generated leaf certificate
when asked for a client certificate as part of the TLS handshake.
To enable mutual TLS,
configure your webhook server to request and verify a client certificate
that chains up to your root CA certificate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      